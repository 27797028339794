<template>
  <div class="">
    <main class="main">
      <section class="section only-bottom">
        <div class="container container--medium">
          <div class="statistic" :class="{ 'loading': loading || statisticLoading.statistic}">
            <div class="statistic__top">
              <div class="statistic__title">
                Статистика клуба ФНС ЛАБ спорт за
                <br>
                <div v-click-outside="onClickOutside" class="statistic__title-open" :class="{ 'active': showDate }"
                  @click="toggleShowDate">
                  <div class="statistic__title-open-text">{{ activeMonth ? activeMonth : activePeriod ? activePeriod : 'весь проект' }}</div>
                  <i class="fa-solid fa-chevron-down"></i>

                  <div v-if="showDate" class="statistic__dropdown active" :class="{ 'with-sidebar': showSidebarMonth || showSidebarSeason }">

                    <a href="javascript:void(0)" class="statistic__dropdown-item" @mouseover="showSidebarMonth = true" @mouseleave="showSidebarMonth = false" @click="showSidebarMonth=true;showSidebarSeason=false;">
                      <span class="statistic__dropdown-title">Месяц</span>
                    </a>

                    <div class="statistic__dropdown sidebar active" v-show="showSidebarMonth" @mouseover="showSidebarMonth = true" @mouseleave="showSidebarMonth = false" @click="showSidebarMonth=true;showSidebarSeason=false;">
                      <a href="javascript:void(0)" v-for="(date, index) in monthsArray" :key="index" class="statistic__dropdown-item sidebar-item"
                        @click="setActiveMonth(date)">
                        <span class="statistic__dropdown-title">{{ $moment(date).format("MMMM YYYY") }}</span>
                      </a>
                    </div>

                    <a href="javascript:void(0)" class="statistic__dropdown-item" @mouseover="showSidebarSeason = true" @mouseleave="showSidebarSeason = false" @click="showSidebarMonth=false;showSidebarSeason=true;">
                      <span class="statistic__dropdown-title">Сезон</span>
                    </a>

                    <div class="statistic__dropdown sidebar active" v-show="showSidebarSeason" @mouseover="showSidebarSeason = true" @mouseleave="showSidebarSeason = false" @click="showSidebarMonth=false;showSidebarSeason=true;">
                      <a href="javascript:void(0)" class="statistic__dropdown-item sidebar-item" @click="setActivePeriod('summer')">
                        <span class="statistic__dropdown-title">Летний сезон 2024 <br><small>(1.05 — 1.10)</small></span>
                      </a>
                      <a href="javascript:void(0)" class="statistic__dropdown-item sidebar-item" @click="setActivePeriod('winter')">
                        <span class="statistic__dropdown-title">Зимний сезон 2025 <br><small>(1.01 — 31.03)</small></span>
                      </a>
                    </div>
                    
                    <a href="javascript:void(0)" class="statistic__dropdown-item" @click="setAllProject()">
                      <span class="statistic__dropdown-title">За весь проект</span>
                    </a>
                  </div>
                </div>
              </div>
            </div>
            <ul class="tabs">
              <li>
                <button class="tabs__item" :class="{ 'active': !params.type_groups && !params.type }"
                  @click="setTypeAll">
                  Все тренировки
                </button>
              </li>
              <template v-if="trainingType && trainingType.groups">
                <li
                  v-for="item in trainingType.groups.filter(f => f.code == 'running' || f.code == 'walking' || f.code == 'biking')"
                  :key="item.code">
                  <button :disabled="statisticLoading.statistic" class="tabs__item"
                    :class="{ 'active': params.type_groups && params.type_groups == item.code }"
                    @click="setTypeFilter(item.code, 'type_groups')">
                    <img v-if="item.icon" :src="item.icon.url" style="width: 25px;"
                      :class="{ 'active-icon': params.type_groups && params.type_groups == item.code }" />
                    {{ item.name }}
                  </button>
                </li>
                <li
                  v-for="item in trainingType.groups.find(f => f.code == 'winter_sport')?.types.filter(i => i.code == 'cross_skiing')"
                  :key="item.code">
                  <button :disabled="statisticLoading.statistic" class="tabs__item"
                    :class="{ 'active': params.type && params.type == item.code }"
                    @click="setTypeFilter(item.code, 'type')">
                    <img v-if="item.icon" :src="item.icon.url" style="width: 25px;"
                      :class="{ 'active-icon': params.type && params.type == item.code }" />
                    {{ item.name }}
                  </button>
                </li>
              </template>
            </ul>
            <div class="tabs-line">
              <ul class="tabs-line-content">
                <li>
                  <button 
                    class="tabs-line__item"
                    :disabled="statisticLoading.statistic"
                    :class="{ active: activeTab === 'all' }"
                    @click="setActiveTab('all')"
                  >
                    Все участники
                  </button>
                </li>
                <li>
                  <button 
                    v-if="group !== null"
                    :disabled="statisticLoading.statistic"
                    class="tabs-line__item"
                    :class="{ active: activeTab === 'group' }"
                    @click="setActiveTab('group')"
                  >
                    Моя группа
                  </button>

                  <button 
                    v-else
                    class="tabs-line__item"
                    @click="showModal"
                  >
                    Моя группа
                  </button>
                </li>
                <li>
                  <button 
                    v-if="subunit !== null && group !== null"
                    :disabled="statisticLoading.statistic"
                    class="tabs-line__item"
                    :class="{ active: activeTab === 'subunit' }"
                    @click="setActiveTab('subunit')"
                  >
                    Мое подразделение
                  </button>
                  <button 
                    v-else
                    class="tabs-line__item"
                    @click="showModal"
                  >
                    Мое подразделение
                  </button>
                </li>
              </ul>
            </div>
            <div v-if="statisticLoading.statistic" class="statistic__body" style="display: flex; align-items: center;">
              <span class="loader"></span>
            </div>
            <div v-else class="statistic__body">
              <div v-if="statistic && statistic.total" class="statistic__info">
                <div v-if="statistic && statistic.total && statistic.total.number !== null"
                  class="statistic__info-item">
                  <p class="statistic__info-title">Количество тренировок</p>
                  <p class="statistic__info-num">{{ statistic.total.number }}</p>
                </div>
                <div v-if="statistic && statistic.total && statistic.total.distance_km !== null"
                  class="statistic__info-item">
                  <p class="statistic__info-title">Дистанция</p>
                  <p class="statistic__info-num">
                    <span>{{ statistic.total.distance_km }}</span>
                    км
                  </p>
                </div>
                <div v-if="statistic && statistic.total && statistic.total.users !== null" class="statistic__info-item">
                  <p class="statistic__info-title">В тренировках участвует</p>
                  <p class="statistic__info-num">
                    <span>{{ statistic.total.users }}</span>
                    чел
                  </p>
                </div>
              </div>
              <div v-if="activeMonth" style="background-color: #fff;" class="statistic__graph">
                <BarChart v-if="statistic && statistic.daily" />
              </div>
            </div>
          </div>
        </div>
      </section>
    </main>
    <v-dialog />
  </div>
</template>

<script>
import { mapGetters, mapActions } from 'vuex'
import BarChart from './BarChart.vue'

export default {
  name: 'StatisticPage',

  components: {
    BarChart
  },

  data() {
    return {
      showDate: false,
      activeMonth: '',
      activePeriod: '',
      loading: false,
      group: null,
      subunit: null,
      showSidebarMonth: false,
      showSidebarSeason: false,
      activeTab: 'all',
      subunitId: null,
    }
  },

  computed: {
    ...mapGetters('statistic', ['trainingType', 'statistic', 'params', 'statisticLoading']),
    monthsArray() {
      const moment = require('moment');
      let startMonth = moment('2024-01-01').month(4);
      let endMonth = moment().startOf('month');
      let months = [];

      while (startMonth.isBefore(endMonth) || startMonth.isSame(endMonth, 'month')) {
        months.push(startMonth.format('YYYY-MM'));
        startMonth.add(1, 'month');
      }

      return months;
    },
  },

  mounted() {
    const savedValue = localStorage.getItem('selectedValueForStatistic');

    if (savedValue) {
      if (savedValue === 'летний сезон 2024') {
        this.changeParams({
          period_from: "2024-05",
          period_to: "2024-10",
          changeFor: "period_range"
        });
        this.activePeriod = savedValue
      } else if (savedValue === 'зимний сезон 2025') {
        this.changeParams({
          period_from: "2025-01",
          period_to: "2025-03",
          changeFor: "period_range"
        });
        this.activePeriod = savedValue
      } else {
        this.activeMonth = this.$moment(savedValue).format("MMMM YYYY");
        this.changeParams({
          value: savedValue,
          changeFor: 'period',
        });
      }
    } else {
      const paramsDateFormat = this.$moment().format("YYYY-MM");
      this.changeParams({
        value: paramsDateFormat,
        changeFor: 'period',
      });
      this.activeMonth = this.$moment().startOf('month').format("MMMM YYYY");
    }

    this.setActiveTab('all');

    this.group = this.$store.getters['auth/user'].club.references[0] || null
    this.subunit = this.$store.getters['auth/user'].club.references[1] || null
  },
  methods: {
    showModal() {
      this.$modal.show('dialog', {
        text: `Для просмотра данных по вашему подразделению необходимо указать его <a href="/profile" target="_blank">в профиле</a>.`,
        buttons: [
          {
            title: 'Закрыть',
            handler: () => {
              this.$modal.hide('dialog')
            },
          },
        ],
      })
    },
    handleTabChange(tab) {
      this.activeTab = tab;
      this.setActiveTab(tab); 
    },
    async setActiveTab(tab) {
      this.activeTab = tab;

      if (tab === 'all') {
        this.subunitId = null;
      } else if (tab === 'group') {
        this.subunitId = this.$store.getters['auth/user'].club.references[0] || null;
      } else if (tab === 'subunit') {
        this.subunitId = this.$store.getters['auth/user'].club.references[1] || null;
      }
      await this.$store.dispatch('statistic/getClubStatistic', {
        subunitId: this.subunitId,
        loading: true
      });
    },
    toggleShowDate() {
      if (window.innerWidth >= 900) {
        this.showDate = !this.showDate;
        this.showSidebarMonth = false;
        this.showSidebarSeason = false;
      } else {
        this.showDate = true;
      }
    },

    onClickOutside(e) {
      if (!e.target.closest('.statistic__title-open')) {
        this.showDate = false;
        this.showSidebarMonth = false;
        this.showSidebarSeason = false;
      }
    },
    
    ...mapActions('statistic', ['changeParams', 'getClubStatistic']),

    setTypeAll() {
      this.loading = true
      this.changeParams({
        value: null,
        changeFor: 'type_groups',
      })
      this.changeParams({
        value: null,
        changeFor: 'type',
      })
      this.getClubStatistic({ loading: true, subunitId: this.subunitId })
        .finally(() => {
          this.loading = false
        })
    },
    setTypeFilter(item, tabType) {
      this.loading = true
      if (tabType === 'type') {
        this.changeParams({
          value: null,
          changeFor: 'type_groups',
        })
      } else {
        this.changeParams({
          value: null,
          changeFor: 'type',
        })
      }
      this.changeParams({
        value: item,
        changeFor: tabType,
      })
      this.getClubStatistic({ loading: true, subunitId: this.subunitId })
        .finally(() => {
          this.loading = false
        })
    },
    setActiveMonth(date) {
      this.loading = true
      this.activeMonth = this.$moment(date).format("MMMM YYYY")
      this.activePeriod = ''
      this.showSidebarMonth = false
      this.showSidebarSeason = false
      const paramsDateFormat = this.$moment(date).format("YYYY-MM")
      localStorage.setItem('selectedValueForStatistic', paramsDateFormat);
      this.changeParams({
        value: paramsDateFormat,
        changeFor: 'period',
      })
      this.getClubStatistic({ loading: true, subunitId: this.subunitId })
        .finally(() => {
          this.loading = false;
          this.$nextTick(() => {
            this.showDate = false;
          });
        });
    },
    setActivePeriod(season) {
      this.loading = true

      let periodFrom = ''
      let periodTo = ''
      this.showSidebarMonth = false
      this.showSidebarSeason = false

      if (season === "summer") {
        periodFrom = "2024-05";
        periodTo = "2024-10";
        this.activePeriod = "летний сезон 2024";
      } else if (season === "winter") {
        periodFrom = "2025-01";
        periodTo = "2025-03";
        this.activePeriod = "зимний сезон 2025";
      }

      this.activeMonth = ''

      localStorage.setItem('selectedValueForStatistic', this.activePeriod)

      this.changeParams({
        period_from: periodFrom,
        period_to: periodTo,
        changeFor: "period_range"
      });

      this.getClubStatistic({ loading: true, subunitId: this.subunitId })
        .finally(() => {
          this.loading = false;
          this.$nextTick(() => {
            this.showDate = false;
          });
        });
    },
    setAllProject() {
      this.loading = true
      this.activeMonth = ''
      this.activePeriod = ''
      this.showDate = false
      this.showSidebarMonth = false
      this.showSidebarSeason = false
      this.changeParams({
        period_from: null,
        period_to: null,
        changeFor: "period_range"
      });
      this.changeParams({
        value: null,
        changeFor: 'period',
      });
      this.getClubStatistic({ loading: true, subunitId: this.subunitId })
        .finally(() => {
          this.loading = false;
          this.$nextTick(() => {
            this.showDate = false
          })
        });
    },
  }
}
</script>

<style>
.tabs::-webkit-scrollbar {
  visibility: hidden;
  width: 0px;
}

.active-icon {
  filter: invert(1);
}

.loading {
  opacity: 0.7;
  pointer-events: none;
}
.statistic__graph {
  padding: 32px;
  border-radius: 12px;
}
</style>
