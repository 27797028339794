<template>
  <div>
    <Header />
    <RatingComponent />
    <Footer />
  </div>
</template>

<script>
import RatingComponent from '@/components/Rating/index.vue'
import Header from '@/components/Header.vue'
import Footer from '@/components/Footer.vue'
import { mapActions } from 'vuex'
export default {
  name: 'Statistic',
  components: {
    RatingComponent,
    Header,
    Footer
  },
  async created() {
    await this.$store.dispatch('statistic/getTrainingTypes')
  },
  methods: {
    ...mapActions('rating', ['clear']),
  },
  destroyed() {
    this.$store.dispatch('rating/clear')
  },
}
</script>
