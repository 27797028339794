import axios from 'axios'
import settings from '@/settings'

export default {
  namespaced: true,
  state: {
    trainingType: [],
    statistic: [],
    params: {},
    loading: {
      statistic: true,
    },
  },

  getters: {
    trainingType(state) {
      return state.trainingType
    },
    statistic(state) {
      return state.statistic
    },
    params(state) {
      return state.params
    },
    statisticLoading(state) {
      return state.loading
    }
  },

  actions: {
    async getTrainingTypes({ commit }) {
      let training = await axios.get(`${settings.APP_URL}dict/tracker/training-type-groups`)

      commit('SET_TRAINING_TYPE', training.data)
    },
    async getClubStatistic({ commit, state }, payload = {}) {
      if (payload.loading) {
        commit('START_GET_STATISTIC_LOADING')
      }
      const paramsPeriod = state.params.period ? `period=${state.params.period}` : '';

      const paramsTypeGroup = state.params.type_groups ? `type_group=${state.params.type_groups}&` : '';

      const paramsType = state.params.type ? `type=${state.params.type}&` : '';

      const paramsPeriodFrom = state.params.period_from ? `period_from=${state.params.period_from}&` : '';

      const paramsPeriodTo = state.params.period_to ? `period_to=${state.params.period_to}` : '';

      let url = `${settings.APP_URL}clubs/${settings.CLUB_ID}/tracker/statistics?${paramsTypeGroup}${paramsType}${paramsPeriod}${paramsPeriodFrom}${paramsPeriodTo}`;
      
      if (payload.subunitId) {
        url += `&reference=${payload.subunitId}`;
      }

      let statistic = await axios.get(url, {
        headers: {
          Authorization: `Bearer ${localStorage.getItem('token')}`
        }
      });

      commit('SET_STATISTIC', statistic.data.statistics);
      commit('FINISH_GET_STATISTIC_LOADING')
    },
    changeParams({ commit }, payload) {
      commit('CHANGE_PARAMS', payload)
    },
    clear({ commit }) {
      commit('clear');
    },
  },

  mutations: {
    SET_TRAINING_TYPE(state, item) {
      state.trainingType = item
    },
    SET_STATISTIC(state, statistic) {
      state.statistic = statistic
    },
    CHANGE_PARAMS(state, payload) {
      if (payload.changeFor === "period_range") {

        if (payload.period_from) {
          state.params.period_from = payload.period_from;
        } else {
          delete state.params.period_from;
        }
    
        if (payload.period_to) {
          state.params.period_to = payload.period_to;
        } else {
          delete state.params.period_to;
        }
    
        delete state.params.period;
    
      } else if (payload.changeFor === "period") {
        state.params.period = payload.value;
        delete state.params.period_from;
        delete state.params.period_to;
      } else if (payload.changeFor) {
        state.params[payload.changeFor] = payload.value;
    
        if (payload.value === "") {
          delete state.params[payload.changeFor];
        }
      }
    },
    START_GET_STATISTIC_LOADING(state) {
      state.loading.statistic = true;
    },
    FINISH_GET_STATISTIC_LOADING(state) {
      state.loading.statistic = false;
    },
    clear(state) {
      state.statistic = [];
      state.params = {};
      state.loading = {
        statistic: true,
      };
    },
  }
}
