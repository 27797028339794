import axios from 'axios'
import settings from '@/settings'

export default {
  namespaced: true,
  state: {
    rating: [],
    repsonal_rating: [],
    params: {
      page: 1,
    },
    meta: null,
    total: null,
    infiniteId: +new Date(),
    loading: {
      rating: true,
    },
  },

  getters: {
    rating(state) {
      return state.rating
    },
    params(state) {
      return state.params
    },
    meta(state) {
      return state.meta
    },
    infiniteId(state) {
      return state.infiniteId
    },
    ratingLoading(state) {
      return state.loading
    },
    total(state) {
      return state.total
    },
    repsonal_rating(state) {
      return state.repsonal_rating
    }
  },

  actions: {
    async getClubRating({ commit, state }, payload = {}) {
      if (payload.loading) {
        commit('START_GET_RATING_LOADING');
      }
      console.log(state.params);
      const paramsPeriod = state.params.period ? `period=${state.params.period}&` : '';
      const paramsTypeGroup = state.params.type_group ? `type_group=${state.params.type_group}&` : '';
      const paramsType = state.params.type ? `type=${state.params.type}&` : '';
      const paramsPage = state.params.page ? `page=${state.params.page}` : '';
      const paramsPeriodFrom = state.params.period_from ? `period_from=${state.params.period_from}&` : '';
      const paramsPeriodTo = state.params.period_to ? `period_to=${state.params.period_to}&` : '';
      let url = `${settings.APP_URL}clubs/${settings.CLUB_ID}/tracker/rating?${paramsTypeGroup}${paramsType}${paramsPeriod}${paramsPeriodFrom}${paramsPeriodTo}${paramsPage}`;
      if (payload.subunitId) {
        url += `&reference=${payload.subunitId}`;
      }

      let rating = await axios.get(url, {
        headers: {
          Authorization: `Bearer ${localStorage.getItem('token')}`
        }
      });

      if (payload.isInfinite) {
        commit('SET_LIST_INFINITE', rating.data);
      } else {
        commit('SET_RATING', rating.data);
      }
      commit('FINISH_GET_RATING_LOADING');
      commit('SET_META', rating.data.meta);
      commit('SET_PERSONAL', rating.data.personal)
    },
    changeParams({ commit }, payload) {
      commit('CHANGE_PARAMS', payload);
    },
    clear({ commit }) {
      commit('clear');
    },
  },

  mutations: {
    SET_RATING(state, rating) {
      state.rating = rating.rating;
      state.total = rating.total;
      state.params.page++;
      state.infiniteId++
    },
    CHANGE_PARAMS(state, payload) {
      if (payload.changeFor === "period_range") {

        if (payload.period_from) {
          state.params.period_from = payload.period_from;
        } else {
          delete state.params.period_from;
        }
    
        if (payload.period_to) {
          state.params.period_to = payload.period_to;
        } else {
          delete state.params.period_to;
        }
    
        delete state.params.period;
    
      } else if (payload.changeFor === "period") {
        state.params.period = payload.value;
        delete state.params.period_from;
        delete state.params.period_to;
      } else if (payload.changeFor) {
        state.params[payload.changeFor] = payload.value;
    
        if (payload.value === "") {
          delete state.params[payload.changeFor];
        }
      }
    },
    START_GET_RATING_LOADING(state) {
      state.loading.rating = true;
    },
    FINISH_GET_RATING_LOADING(state) {
      state.loading.rating = false;
    },
    SET_META(state, meta) {
      state.meta = meta;
    },
    SET_PERSONAL(state, personal) {
      state.repsonal_rating = personal;
    },
    SET_LIST_INFINITE(state, rating) {
      state.rating.push(...rating.rating);
      state.params.page++;
    },
    clear(state) {
      state.rating = [];
      state.params.page = 1;
      state.loading = {
        rating: true,
      };
      state.meta = null;
      state.repsonal_rating = [];
    },
  }
}
