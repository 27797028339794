<template>
  <div>
    <Header />
    <StatisticComponent />
    <Footer />
  </div>
</template>

<script>
import { mapActions } from 'vuex'
import StatisticComponent from '@/components/Statistic/index.vue'
import Header from '@/components/Header.vue'
import Footer from '@/components/Footer.vue'

export default {
  name: 'Statistic',
  components: {
    StatisticComponent,
    Header,
    Footer
  },
  async created() {
    await this.$store.dispatch('statistic/getTrainingTypes')
  },
  methods: {
    ...mapActions('statistic', ['clear']),
  },
  destroyed() {
    this.$store.dispatch('statistic/clear')
  },
}
</script>
